import "./App.css";
import "./assets/CSS/Responsive.css";
import banner from "../src/img/Beta-banner2.png";
import banner2 from "../src/img/banner-21.png";
import Slider from "infinite-react-carousel";
import Videos from "../src/img/Team-Videos.png";
import IPPhone from "../src/img/IpPhone.png";
import Phone from "../src/img/phone-calling.png";
import Webex from "../src/img/why-beta.png";
import Logo from "../src/img/WebexBeta_logo.png";
import FooterLogo from "../src/img/Cisco-footer.png";

function App() {
  const bannerSettings = {
    slidesToShow: 1,
    arrows: false,
    dots: true,
  };

  return (
    <div className="App">
      <header id="header" className="fixed-top ">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto float-left">
            <a href="index.html">
              <img src={Logo}  alt="logo"/>
            </a>
          </h1>
          <div className="header-link float-right">
            <a
              href="https://community.cisco.com/t5/custom/page/page-id/community-trial-page?category-id=2331j-collab-ccp"
              className="login ripple"
            >
              Login
            </a>
            <a
              href="https://identity.cisco.com/ui/tenants/global/v1.0/enrollment-ui"
              className="sign-in ripple"
            >
              Sign up
            </a>
          </div>
        </div>
      </header>
      <section id="hero" className="d-flex align-items-center">
        <div className="container bann">
          <Slider {...bannerSettings}>
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 hero-desc"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>
                  Get to know the Webex
                  <br />
                  <span className="blue">Beta Program</span>
                </h1>
                <h3>
                  Our Beta Program gives admin users the chance to experience
                  what’s coming from our Webex collaboration portfolio before
                  anyone else.
                </h3>
                <p>
                  This is your chance to help shape the future of Webex and
                  contribute towards an even better product for you and your
                  users. With feedback from you, we will be able to better serve
                  you and your teams
                </p>
                <div className="d-lg-flex">
                  <a
                    href="https://identity.cisco.com/ui/tenants/global/v1.0/enrollment-ui"
                    className="btn-get-started scrollto"
                  >
                    Sign up today
                  </a>
                  <span>
                    Already a member?{" "}
                    <a href="https://community.cisco.com/t5/custom/page/page-id/community-trial-page?category-id=2331j-collab-ccp">
                      Click here
                    </a>
                  </span>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src={banner} className="img-fluid animated" alt="" />
              </div>
            </div>

            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 hero-desc"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>
                  Introducing the Webex <br />
                  <span className="blue">Beta Program</span>
                </h1>
                <h3>
                  By joining the Webex Beta admin Community, you can get access
                  to the latest pre-released products and features across Webex
                  Calling, Meetings, and Devices.
                </h3>
                <p>
                  In return, we ask that you provide regular feedback about the
                  product or features to our Webex Beta team.
                </p>
                <div className="d-lg-flex">
                  <a
                    href="https://identity.cisco.com/ui/tenants/global/v1.0/enrollment-ui"
                    className="btn-get-started scrollto"
                  >
                    Sign up today
                  </a>
                  <span>
                    Already a member?{" "}
                    <a href="https://community.cisco.com/t5/custom/page/page-id/community-trial-page?category-id=2331j-collab-ccp">
                      Click here
                    </a>
                  </span>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img sec-banner"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src={banner2} className="img-fluid animated" alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <section id="main">
        <div className="features">
          <div className="container">
            <h3>Webex solutions on offer in Beta</h3>
            <div className="row">
              <div className="col-lg-4 ">
                <div className="card">
                  <div className="card-wrapper">
                    <div className="image">
                      <img src={Videos} alt="video"/>
                    </div>
                    <div className="desc">
                      <h4>Webex App</h4>
                      {/* <p>Unified Communications and Calling</p> */}
                    </div>
                  </div>

                  <div className="hover-card">
                    <h4>Webex App</h4>
                    <p>
                      Your one easy-to-use and secure app to call, message, meet
                      and get work done.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card ">
                  <div className="card-wrapper">
                    <div className="image">
                      <img src={IPPhone}  alt="IPPhone"/>
                    </div>
                    <div className="desc text-left">
                      <h4>Unified Communications and Calling</h4>
                      {/* <p>Webex Teams and Meetings</p> */}
                    </div>
                  </div>
                  <div className="hover-card">
                    <h4>Unified Communications and Calling</h4>
                    <p>
                      On-Premise Call Control, Unified Communications
                      Applications and Cloud Calling
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card ">
                  <div className="card-wrapper">
                    <div className="image">
                      <img src={Phone} alt="phone"/>
                    </div>
                    <div className="desc">
                      <h4>Webex Rooms and Devices</h4>
                      {/* <p>Webex Teams and Meetings </p> */}
                    </div>
                  </div>
                  <div className="hover-card">
                    <h4>Webex Rooms and Devices</h4>
                    <p>
                      Video conferencing and collaboration devices for
                      effortless new ways to connect with your team, share your
                      ideas, and express yourself.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-webex">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img src={Webex} alt="Webex"/>
              </div>
              <div className="col-lg-6">
                <div className="desc">
                  <h2>
                    Give the Webex Beta
                    <span>Program a try</span>
                  </h2>
                  <p>
                    We designed our trials program to empower you and give you
                    early access to our most exciting upcoming products and
                    features.
                  </p>
                  <p>
                    {" "}
                    Play around, tell us what you love and what needs work, and
                    share any feedback you have so we can make our products
                    better for you. Your voice is what will drive the future of
                    Webex.
                  </p>
                  <p className="font-16 why-p">
                    When you sign up for our Beta Program, you also get to
                    connect with us directly though our Cisco Customer
                    Connection Program and with a wider community of thousands
                    of enthusiasts just like you.
                  </p>
                  <ul>
                    <li>Be the first to see what’s next from Webex.</li>
                    <li>
                      Make your voice heard by sharing feedback directly with
                      our engineers and designers
                    </li>
                    <li>
                      Join an awesome community of like-minded participants{" "}
                    </li>
                    <li>
                      Enjoy benefits that only trials participants get when they
                      sign up
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-main sign-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center ">
                <h3 className="white">
                  Get started today to be part of
                  <br /> something truly awesome
                </h3>
                <a
                  href="https://identity.cisco.com/ui/tenants/global/v1.0/enrollment-ui"
                  className="button"
                >
                  Sign up
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="link-list">
          <div className="container">
            <div className="row cards">
              <div className="link-list-header">
                <div className="col-lg-12">
                  <h2>How to participate</h2>
                  <p>
                    Are you an admin user and want to join our Beta Program?
                    Follow the steps below and you’ll be good to go. It’s really
                    that simple!
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="grid-layout">
                      <div className="numbers-bull register">
                        <span>1</span>
                      </div>
                      <div className="grid-details">
                        <h3>Register</h3>
                        <p>
                          Register for our free Webex Beta Program for admins
                          and join our exciting community of Webex enthusiasts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="grid-layout">
                      <div className="numbers-bull experience">
                        <span className="">2</span>
                      </div>
                      <div className="grid-details">
                        <h3>Experience</h3>
                        <p>
                          See what products are available in the Beta Program
                          and apply to participate in the one you want to try.
                          Then, play around and have fun.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="grid-layout">
                      <div className="numbers-bull feedback">
                        <span className="">3</span>
                      </div>
                      <div className="grid-details">
                        <h3>Give feedback</h3>
                        <p>
                          Share your valuable feedback by telling us how you
                          felt using the product and how we can improve.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="row footer-width">
              <div className="col-lg-12 ">
                <div className="card">
                  <h4>Webex Community Page</h4>
                  <a href="https://community.cisco.com/">
                    <span>Join the Conversation</span>
                  </a>
                </div>
                <div className="card">
                  <h4>Webex Essentials</h4>
                  <a href="https://essentials.webex.com/">
                    <span>Learn more</span>
                  </a>
                </div>
              </div>
            </div>
            <footer>
              <div className="row">
                <div className="col-lg-12">
                  <img
                    src={FooterLogo}
                    alt="Cisco Logo"
                    className="footer-logo"
                  />
                  <p>
                    © 2020 Cisco and/or its affiliates. All rights reserved.
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
